<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <ResponseAlert ref="response" />
        <div class="col-sm-12 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div class="col-md-6 col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row">
            <div
              class="input-group col-lg-7 col-md-8 px-0"
            >
              <input
                v-model="config.search"
                type="text"
                placeholder="Cari kata kunci"
                class="form-control"
                @keyup.enter="config.page = 1; get()"
              >
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  @click="config.page = 1; get()"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
            <b-button
              v-if="hasPermission('filter/purchase-order')"
              v-b-modal.modal-filter-po
              variant="secondary"
              class="btn-min-width rounded full-width-responsive"
            >
              Advance Filter
            </b-button>
          </div>
        </div>
        <b-col
          sm="12"
          class="mt-2 overflow-auto"
        >
          <b-button-group>
            <b-button
              v-for="status, idx in masters.status"
              :key="idx"
              :variant="models.status === status ? 'primary' : 'link'"
              @click="models.status = status; setAdditionalParams(status);"
            >
              {{ status }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          sm="12"
          class="overflow-auto"
        >
          <b-button-group>
            <b-button
              v-for="status, idx in subStatus"
              :key="idx"
              :variant="models.subStatus === status ? 'primary' : 'link'"
              @click="models.subStatus = status; setAdditionalParams(status);"
            >
              {{ status }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          sm="12"
          class="mt-1"
        >
          <div class="table-responsive">
            <table class="table b-table">
              <thead>
                <tr>
                  <th
                    v-for="(row, key) in config.headers"
                    :key="key"
                    :width="row.width"
                    :class="row.class"
                  >
                    <a
                      v-if="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                      @click="sort(row.value)"
                    >
                      {{ row.title }}
                      <i
                        :id="row.value"
                        class="fa fa-sort"
                      />
                    </a>
                    <a
                      v-else
                      :id="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                    >
                      {{ row.title }}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="config.total_data">
                  <tr
                    v-for="(row, key) in config.rows"
                    :key="key"
                  >
                    <td>{{ row.date }}</td>
                    <td>{{ row.poNumber }}</td>
                    <td>{{ row.typeText }}</td>
                    <td>{{ row.requestBy }}</td>
                    <td>{{ row.level }}</td>
                    <td>
                      Rp. {{ parseFloat(row.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>
                    <td>{{ row.acceptedBy || 'Admin' }}</td>
                    <td>{{ row.internalStatus }}</td>
                    <td align="center">
                      <div class="d-flex justify-content-center">
                        <custom-button
                          permission="detail/purchase-order"
                          :url="`/${config.uri}/${row.id}`"
                          title="Detail"
                          class-name="btn-action"
                        >
                          <feather-icon icon="EyeIcon" />
                        </custom-button>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-if="!config.total_data">
                  <td
                    :colspan="config.headers.length"
                    align="center"
                  >
                    No data available.
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="config.total_data">
                <tr>
                  <td
                    :colspan="config.headers.length"
                    class="p-0"
                  >
                    <div class="d-flex justify-content-between mt-2">
                      <b-form-select
                        v-model="config.per_page"
                        :options="perPageOptions"
                        class="w-auto"
                      />
                      <b-pagination
                        v-model="config.page"
                        :total-rows="config.total_data"
                        :per-page="config.per_page"
                        @change="gotoPage"
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-filter-po"
      ref="modal-filter-po"
      centered
      title="Filter"
      hide-footer
      no-close-on-backdrop
    >
      <form
        @submit.prevent="advanceSearch"
      >
        <div class="animated fadeIn">
          <b-row
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="jenis"
              >Jenis PO</label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                id="jenis"
                v-model="models.type"
                :options="masters.type"
                class="w-100"
              />
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <b-form-datepicker
                id="date"
                v-model="models.date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                class="w-100"
              />
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="target"
              >Request By</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="tag"
                v-model="models.requestBy"
                clearable
                label="name"
                placeholder="- Pilih Pengguna -"
                :options="masters.kfs"
                :reduce="options => options.name"
                @search="value => debounceSearch(value, 'kfs')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="exportExcel"
            >
              Export Data
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-min-width rounded mx-0 mx-md-1 my-1 my-md-0"
              @click="advanceSearch('reset')"
            >
              Reset
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { exportExcel } from '@/utils/helpers'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BPagination, BFormSelect, BButton, BFormDatepicker, BButtonGroup,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'

export default {
  name: 'ListPurchaseOrder',
  metaInfo: {
    title: 'List PO',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BFormDatepicker,
    BButtonGroup,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.purchaseOrders,
        rows: [],
        additional_params: {
          status: null,
          type: null,
          date: null,
          requestBy: '',
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Tanggal',
          value: 'date',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Kode PO',
          value: 'uniqueId',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Jenis PO',
          value: 'type',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Request By',
          value: 'requestBy',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Level',
          value: 'level',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Total PO',
          value: 'totalPayment',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Accepted By',
          value: 'acceptedBy',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Status',
          value: 'internalStatus',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Action',
          value: '',
          class: 'text-center',
          width: '10%',
        }],
      },
      masters: {
        kfs: [],
        type: [
          {
            text: 'Product',
            value: 1,
          },
          {
            text: 'Bahan Baku',
            value: 2,
          },
        ],
        status: [
          'Semua',
          'Permintaan',
          'Diterima',
          'Ditolak',
          'Komplain',
          'Dibatalkan',
        ],
        subStatus: {
          diterima: [
            'Menunggu Pembayaran',
            'Siap Kirim',
            'Selesai',
          ],
          komplain: [
            'Pengajuan',
            'Proses',
            'Selesai',
          ],
        },
      },
      models: {
        status: 'Semua',
        subStatus: '',

        // filter
        type: null,
        date: null,
        requestBy: '',
      },
    }
  },
  computed: {
    status() {
      return this.models.status
    },
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    subStatus() {
      if (this.models.status === 'Diterima') {
        return this.masters.subStatus.diterima
      } if (this.models.status === 'Komplain') {
        return this.masters.subStatus.komplain
      }
      return []
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    status(newVal) {
      if (newVal === 'Diterima' || newVal === 'Komplain') {
        const [status] = this.subStatus
        this.models.subStatus = status
      }
    },
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
    _.getListOption()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.purchaseOrders}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    getListOption() {
      const getKFS = this.$axios.get(`${api.list_kfs}`)

      Promise.all([getKFS]).then(res => {
        this.masters.kfs = [...res[0].data.data]
      })
    },
    setAdditionalParams(value) {
      let status = null
      let statusComplain
      switch (value) {
        case 'Semua':
          status = null
          statusComplain = null
          break
        case 'Permintaan':
          status = 0
          statusComplain = null
          break
        case 'Diterima':
        case 'Menunggu Pembayaran':
          status = 1
          statusComplain = null
          break
        case 'Siap Kirim':
          status = 3
          statusComplain = null
          break
        case 'Selesai':
          if (this.models.status === 'Diterima') {
            status = 4
            statusComplain = null
          } else {
            status = null
            statusComplain = 2
          }
          break
        case 'Komplain':
        case 'Pengajuan':
          status = -3
          statusComplain = 0
          break
        case 'Proses':
          status = -3
          statusComplain = 1
          break
        case 'Dibatalkan':
          status = -1
          statusComplain = null
          break
        case 'Ditolak':
          status = -2
          statusComplain = null
          break
        default:
          break
      }
      this.config.additional_params.status = status
      this.config.additional_params.statusComplain = statusComplain
      this.get()
    },
    debounceSearch(value) {
      if (value !== '') {
        this.search.kfs = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.kfs = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_kfs}?search=${this.search.kfs}`)
      return res.data.data.rows
    },
    advanceSearch(value) {
      if (value === 'reset') {
        this.config.additional_params.date = this.models.date = null
        this.config.additional_params.type = this.models.type = null
        this.config.additional_params.requestBy = this.models.requestBy = null
      } else {
        if (this.models.date) this.config.additional_params.date = this.models.date
        if (this.models.type) this.config.additional_params.type = this.models.type
        if (this.models.requestBy) this.config.additional_params.requestBy = this.models.requestBy
      }
      this.config.page = 1
      this.get()
      this.$bvModal.hide('modal-filter-po')
    },
    exportExcel() {
      exportExcel('List Purchase Order Sales', `${api.purchaseOrder}/exports-sales`, {
        type: this.models.type,
        date: this.models.date,
        requestBy: this.models.requestBy,
      })
    },
  },
}
</script>
